import React from 'react'
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'

const AcceptableUsePolicy = () => {
    return (
        <Layout page="Acceptable Use Policy">
            <PageBanner
                pageTitle="Acceptable Use Policy" 
                crumbs={[{page: "Home", url: "/"}]}  
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <p><i>This Acceptable Use Policy was last updated on February 1, 2021.</i></p>
                                <blockquote className="blockquote">
                                    <p>This is the acceptable use policy, which, together with our Terms of Service, sets out the terms under which we Connection Goal Ltd allow you to use our site www.connectiongoal.com (“site”) whether you are a visitor or a registered user. All enquiries should be directed to hello@connnectiongoal.com. Please read the terms of this policy carefully, as by using our site you indicate that you agree to comply with and be bound by them.
                                    </p>
                                </blockquote>
                                <h3>Prohibited Uses of our Site</h3>
                                <p>Whether you are a visitor or registered user, you must comply with our Terms of Service, and use our site for lawful purposes only. In particular, you must not use our site for the uses listed (without limitation) below:
                                    <ul>
                                        <li> any fraudulent activity;</li>
                                        <li>any activity which breaches any applicable law or regulation, whether national or international;</li>
                                        <li>any activity which may cause or result in harm to a child under 18 years of age;</li>
                                        <li>sending unsolicited advertising or other content (spam), or entering into any arrangement for such material to be sent;</li>
                                        <li>reproducing, selling or otherwise handling our site or its contents in breach of our terms of website use;</li>
                                        <li>knowingly introducing to our site, or transmitor attempt to transmit to any other site, computer or network, viruses, trojans, worms, logic bombs or other material, code or program which is malicious or technologically harmful;</li>
                                        <li>attempting to gain unauthorised access to our site, our software, our server, or any server, computer or database connected to our site; or</li>
                                        <li>attacking our site via a denial-of-service attack or a distributed denial-of service attack.</li>
                                    </ul>
                                </p>
                                <h3>Contributing and Interacting</h3>
                                <p>Our site may offer users the facilities to upload or contribute content or other material, or to interact with other users. When making use of these facilities, it is your responsibility to ensure that any contribution or interaction is, as far as you are aware, factually correct, represents your honest opinion, and does not breach any applicable law or regulation.<br/>
                                In addition, any contribution or interaction must not include any material which (without limitation):
                                    <ul>
                                        <li>is defamatory, obscene, offensive, hateful or inflammatory;</li>
                                        <li>is, or refers to material which is, sexually explicit;</li>
                                        <li>promotes violence, illegal activity or any form of discrimination;</li>
                                        <li>infringes any other person’s copyright, database right or trade mark;</li>
                                        <li>threatens, harasses, upsets, embarrasses, alarms or annoys any other person, or is likely to do so;</li>
                                        <li>advocates, promotes or assists any illegal activity;</li>
                                        <li>is likely to deceive any person or is made in breach of a legal duty owed to a third party (such as a duty of confidence);</li>
                                        <li>invades another’s privacy or cause inconvenience or anxiety to any person;</li>
                                        <li>is used to impersonate any person, or to misrepresent your identity or affiliation with any person; or</li>
                                        <li>gives the impression that the material emanates from us, if this is not the case.</li>
                                    </ul>
                                </p>
                                <h3>Moderation</h3>
                                <p>If we at any time use our site to provide users with any interactive service, the following moderation provisions will apply:
                                    <ul>
                                        <li>we will notify users if moderation is in place, and, if so, whether the moderation is provided by a person or is automated;</li>
                                        <li>if moderation is in place, we will give you a means to contact the moderator;</li>
                                        <li>although we will do our best to assess any risks which such interactive service may pose, we will be under no obligation to moderate it, and we expressly exclude any liability for any loss or damage to any person caused by use of it; and</li>
                                        <li>children should at all times be supervised when using the interactive services on our site, whether such services are moderated or not.</li>
                                    </ul>
                                We are the owner or the licensee of all intellectual property rights in our site and the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.<br/>
                                You must not use any part of the materials on our site for commercial purposes without a license from us or our licensors. You may not reproduce in any format (including on another website) any part of our site (including content, images, designs, look and feel) without our prior written consent.<br/>
                                If, in our opinion, you are in breach of these provisions, your right to use our site will cease immediately and you must either return or destroy (as required by us) any copies of the materials you have made.<br/>
                                </p>
                                <h3>Breaches of This Policy</h3>
                                <p>Any breach of this acceptable use policy will be dealt with in the same way as breach of our Terms of Website Use, and we reserve the right to take any other action we reasonably deem appropriate, including restricting your use of our site and/or taking legal action against you. We are not liable for any loss or damage caused by any breach of this acceptable use policy.
                                </p>
                                <h3>Amendments</h3>
                                <p>Please check this page regularly, as we may revise this acceptable use policy at any time. We may also change or update our acceptable use policy at any time by means of notices published anywhere on our site.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/opportunities">
                                                Opportunities
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-of-service">
                                                Terms of Service
                                            </Link>
                                        </li> 
                                        <li className="active">
                                            <Link to="/acceptable-use-policy">
                                                Acceptable Use Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AcceptableUsePolicy;